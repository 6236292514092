export default class MaxSelect {
	handleCheckboxes() {
		const jsMaxSelect = document.querySelectorAll('.js-max-select');
		const customSelects = document.querySelectorAll('.c-custom-checkbox__input');
		for (const element of jsMaxSelect) {
			const legend = element.querySelector('.c-form__legend');
			const alert = element.querySelector('.c-form__alert');
			const max = element.dataset.maximumitems;
			legend.innerText = `${legend.innerText} (max ${max} val) `;
			alert.innerText = `Du har nu valt maximalt antal (${max}).`;
		}
		for (const select of customSelects) {
			select.addEventListener('change', function (e) {
				e.preventDefault();
				if (!e.target.closest('.js-max-select')) return;
				const max = +e.target.closest('.js-max-select').dataset.maximumitems;
				const fieldset = e.target.closest('fieldset');
				const checkboxesInGroup = fieldset.querySelectorAll('.c-custom-checkbox__input');
				let alreadySelected = checkboxesInGroup.length ? [].filter.call(checkboxesInGroup, (checkBox) => checkBox.checked).length : 0;
				if (alreadySelected === max) fieldset.classList.add('c-form--showalert');
				else fieldset.classList.remove('c-form--showalert');
				for (const checkbox of checkboxesInGroup) {
					if (!checkbox.checked) checkbox.disabled = alreadySelected === max ? 'disabled' : '';
				}
			});
		}
	}
}